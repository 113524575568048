<template>
    <b-row>
      <b-col cols="12">
        <juno-table />
      </b-col>
    </b-row>
  </template>
  
  <script>
  import { BRow, BCol } from 'bootstrap-vue'
  import JunoTable from './juno-tables/MisconfigData.vue'
 
  
  export default {
    components: {
      BRow,
      BCol,
  
      JunoTable,

    },
  }
  </script>
  
  <style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>
  